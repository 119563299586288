import * as React from "react"
import ITHealthcareBackground from '../../images/services/ithealthcare-background.jpg'
import NetOptimizeBackground from '../../images/services/networkoptimization-background.jpg'
import CyberBackground from '../../images/services/cyber-background.jpg'
import WebBackground from '../../images/services/web-background.jpg'
import { Link } from 'gatsby'

export default function Services() {
  return (
    <div className="grid text-white md:grid-cols-2 lg:grid-cols-3 gap-14">
        <div className="h-full flex flex-col justify-end bg-cover bg-center bg-no-repeat bg-insight rounded-lg p-8 shadow-xl" style={{backgroundImage: `url(${ITHealthcareBackground})`, height: `450px`}}>
            <h2 className="text-4xl font-heading font-bold pb-2">IT Health Care</h2>
            <p>Around the clock care for  all of your most important IT assets. </p>
            <Link to="/it-healthcare">
                 <div className="flex flex-row pt-12">
                    <p className="pr-2 font-body font-bold">Learn More</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </div>
            </Link>
        </div>
        <div className="h-full flex flex-col justify-end bg-cover bg-center bg-no-repeat bg-insight rounded-lg p-8 shadow-xl" style={{backgroundImage: `url(${CyberBackground})`, height: `450px`}}>
            <h2 className="text-4xl font-heading font-bold pb-2">Cyber Security</h2>
            <p>Protect your information and secure your organization against cyber threats.</p>
            <Link to="/security">
                 <div className="flex flex-row pt-12">
                    <p className="pr-2 font-bold">Learn More</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </div>
            </Link>
        </div>
        <div className="h-full flex flex-col justify-end bg-cover bg-center bg-no-repeat bg-insight rounded-lg p-8 shadow-xl" style={{backgroundImage: `url(${WebBackground})`, height: `450px`}}>
            <h2 className="text-4xl font-heading font-bold pb-2">Web Development</h2>
            <p>Custom websites and digital platforms built to grow.</p>
            <Link to="/web-development">
                 <div className="flex flex-row pt-12">
                    <p className="pr-2 font-bold">Learn More</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                </div>
            </Link>
        </div>
    </div>
  )
}

