import * as React from "react"
import { PopupButton } from "@typeform/embed-react"

export default function FooterCta() {
  return (
    <div
      className="w-screen bg-insight py-48"
      style={{
        position: `relative`,
        left: `50%`,
        right: `50%`,
        marginLeft: `-50vw`,
        marginRight: `-50vw`,
      }}
    >
      <h2 className="text-white text-center text-6xl font-heading font-bold">
        Find what you <span className="text-gray-900">deserve.</span>
      </h2>
      <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
        <div className="m-auto">
          <PopupButton
            id="ILHyUo06"
            className="m-auto sr-onlyshadow w-60 flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-insight bg-white hover:bg-insight-darker md:py-4 md:text-lg md:px-10"
          >
            Get Started
          </PopupButton>
        </div>
      </div>
    </div>
  )
}
