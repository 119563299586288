import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/autoplay"
import Cisco from "../../images/logos/cisco.webp"
import IntermediaLogo from "../../images/logos/intermedia_logo.webp"
import Office from "../../images/logos/microsoft_365_logo.webp"
import Azure from "../../images/logos/ms-azure_logo_horiz_c-gray_rgb.webp"
import Rsign from "../../images/logos/rsign-logo.webp"
import Sophos from "../../images/logos/sophos-logo-blue-rgb.webp"
import Storage from "../../images/logos/storage_guardian_logo.webp"
import Veeam from "../../images/logos/veeam_logo_topaz_rgb_2019.webp"
import SwiperCore, { Autoplay } from "swiper"

SwiperCore.use([Autoplay])

export default function Logos() {
  return (
    <div
      className="w-screen"
      style={{
        background: `linear-gradient(transparent 50%, #00B2FF 50%)`,
        position: `relative`,
        left: `50%`,
        right: `50%`,
        marginLeft: `-50vw`,
        marginRight: `-50vw`,
      }}
    >
      <div
        className="m-auto max-w-screen-2xl text-white mt-40 grid grid-cols-1"
        style={{ padding: `0 1.0875rem 1.45rem` }}
      >
        <div className="bg-white rounded-lg px-24 py-8 insight-shadow">
          <Swiper
            breakpoints={{
              // when window width is >= 640px
              640: {
                slidesPerView: 2,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 4,
              },
            }}
            spaceBetween={60}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
          >
            <SwiperSlide className="w-72 flex justify-center h-52 items-center">
              <img src={Cisco} alt="Cisco" />
            </SwiperSlide>
            <SwiperSlide className="w-72 flex justify-center h-52 items-center">
              <img src={IntermediaLogo} alt="Intermedia" />
            </SwiperSlide>
            <SwiperSlide className="w-72 flex justify-center h-52 items-center">
              <img src={Office} alt="Microsoft 365" />
            </SwiperSlide>
            <SwiperSlide className="w-72 flex justify-center h-52 items-center">
              <img src={Azure} alt="Azure" />
            </SwiperSlide>
            <SwiperSlide className="w-72 flex justify-center h-52 items-center">
              <img src={Rsign} alt="RSign" />
            </SwiperSlide>
            <SwiperSlide className="w-72 flex justify-center h-52 items-center">
              <img src={Sophos} alt="Sophos" />
            </SwiperSlide>
            <SwiperSlide className="w-72 flex justify-center h-52 items-center">
              <img src={Storage} alt="Sophos" />
            </SwiperSlide>
            <SwiperSlide className="w-72 flex justify-center h-52 items-center">
              <img src={Veeam} alt="Veeam" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}
