import * as React from "react"
import { Helmet } from "react-helmet"
import Cta from "../components/home/cta"
import Services from "../components/home/services"
import FooterCta from "../components/home/footerCta"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import Particles from "react-particles-js"
import particlesConfig from "../components/home/particlesConfig.json"
import Header from "../components/header"
import Footer from "../components/footer"
import Logo from "../components/home/logos"

import Seo from "../components/seo"

const IndexPage = () => (
  <div>
    <Helmet>
      <script
        async
        defer
        src="https://tools.luckyorange.com/core/lo.js?site-id=fd63c3d4"
      ></script>
    </Helmet>
    <Seo title="Home" />
    <Particles params={particlesConfig} className="home-particles-container" />
    <Header />
    <div
      style={{
        margin: `0 auto`,
        maxWidth: "1536px",
        padding: `0 1.0875rem 1.45rem`,
      }}
    >
      <Cta></Cta>
      <Services></Services>
      <Logo></Logo>
      <FooterCta></FooterCta>
      <Footer />
    </div>
  </div>
)

export default IndexPage
