import * as React from "react"

export default function CTA() {
  return (
          <div className="py-24 mx-auto px-4 sm:py-12 sm:px-6 md:py-36">
            <div className="sm:text-left lg:text-left">
              <h1 className="text-4xl tracking-tight font-heading font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-8xl">
                <span >Small businesses <br></br> deserve</span>{' '}
                <span className="text-insight xl:inline">the best.</span>
              </h1>
              <p className="mt-3 text-base font-body text-gray-900 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-0 md:mt-5 md:text-2xl lg:mx-0">
                Let us worry about the tech.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-start lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="/"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-insight hover:bg-insight-darker md:py-4 md:text-lg md:px-10"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>
  )
}
